import Image from 'next/image'
import styled, { css } from 'styled-components'

import { colors, dimensions, imageSizes, styling, typography } from '#styles'

export const OverTopContainer = styled.div`
  background-color: ${colors.redTorch};
  width: 100%;
`

export const OverTopSection = styled.div`
  width: min(${styling.container.maxWidth.normal}, 100%);
  min-height: 20rem;
  display: grid;
  place-items: center;
  padding: 1rem;
  margin: 0 auto;

  & > {
    display: none;
  }
`
export const FullWidthTextContainer = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const TextContainer = styled.article<{
  color?: string
  backgroundColor?: string
  hideSm?: boolean
  center?: boolean
  marginTop?: boolean
  gap?: string
}>`
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  gap: ${styling.container.gap.lg};
  flex: 1;
  margin-top: ${props => props.marginTop && '2rem'};
  color: ${props => props.color};
  text-align: ${props => (props.center ? 'center' : 'left')};
  display: ${props => (props.hideSm ? 'none' : 'flex')};
  gap: ${props => props.gap};
  @media (max-width: ${dimensions.tablet}) {
    gap: ${styling.container.gap.sm};
  }
  @media (min-width: ${dimensions.tablet}) {
    width: 100%;
    align-items: ${props => (props.center ? 'center' : 'flex-start')};
    display: ${props => props.hideSm && 'flex'};
    margin-top: 0;
  }
  @media (min-width: ${dimensions.laptop}) {
    justify-content: flex-start;
    max-width: 50%;
  }
`

export const ExtraSmGapTextContainer = styled(TextContainer)`
  gap: ${styling.container.gap.xs};

  @media (min-width: ${dimensions.laptop}) {
    max-width: 100%;
  }
`

export const SmallGapTextContainer = styled(TextContainer)`
  gap: ${styling.container.gap.sm};
`

export const ColoredTextContainer = styled.article<{
  backgroundColor?: string
  marginRight?: string
  center?: boolean
}>`
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  gap: ${styling.container.gap.lg};
  flex: 1;
  display: flex;
  gap: ${styling.container.gap.sm};
  text-align: ${props => (props.center ? 'center' : 'left')};
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: -2.5rem;
  max-width: 100%;
  word-break: break-word;
  hyphens: auto;

  @media (min-width: ${dimensions.tablet}) {
    padding: 2rem;
    width: 100%;
    align-items: flex-start;
    margin-top: -2.5rem;
    margin-bottom: 0;
  }
  @media (min-width: ${dimensions.laptop}) {
    max-width: 80%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    margin-right: ${props =>
      props.marginRight ? props.marginRight : '-3.5rem'};
    padding-right: 10rem;
  }
`

export const LargeGapColoredTextContainer = styled(ColoredTextContainer)`
  width: 100%;
  gap: ${styling.container.gap.lg};
  border-radius: 1rem;
  @media (min-width: ${dimensions.laptop}) {
    max-width: 80%;
    border-radius: 1rem 0 0 1rem;
  }
`

const MdHeader = css`
  ${typography.displaySm.bold};
  @media (min-width: ${dimensions.tablet}) {
    ${typography.displayXl.bold};
  }
  @media (min-width: ${dimensions.laptop}) {
    ${typography.displayLg.bold};
  }
`

const LgHeader = css`
  ${typography.displayMd.bold};
  @media (min-width: ${dimensions.tablet}) {
    ${typography.displaySm.bold};
  }
  @media (min-width: ${dimensions.laptop}) {
    ${typography.displayXl.bold};
  }
`

export const TextHeader = styled.h1<{
  size?: 'md' | 'lg'
  color?: string
  align?: 'left' | 'center' | 'right'
}>`
  width: 100%;
  color: ${props => props.color ?? colors.neutral900};
  text-align: ${props => props.align};
  ${({ size }) => (size === 'lg' ? LgHeader : MdHeader)};
`

export const TextSubHeader = styled.h2<{
  size?: 'default' | 'md'
  color?: string
}>`
  ${({ size }) =>
    size === 'md' ? typography.displaySm.bold : typography.displayXs.bold};
  color: ${props => props.color ?? colors.blackAccent};
`

export const TextBody = styled.article<{
  maxWidth?: string
  color?: string
  hideTextOnLargeScreen?: boolean
  showTextOnLargeScreen?: boolean
}>`
  ${typography.bodySm.regular};
  color: ${props => props.color ?? colors.neutral700};
  white-space: pre-line;
  justify-self: flex-start;
  opacity: 1;
  display: ${props => props.showTextOnLargeScreen && 'none'};

  @media (min-width: ${dimensions.laptop}) {
    opacity: ${props => props.hideTextOnLargeScreen && 0};
    display: ${props => props.showTextOnLargeScreen && 'flex'};
    max-width: ${props => props.maxWidth};
    ${typography.bodyMd.regular};
  }
`

export const SecondaryTextBody = styled.article<{
  color?: string
}>`
  ${typography.bodyLg.regular};
  color: ${props => props.color ?? colors.neutral700};
  white-space: pre-line;
  justify-self: flex-start;

  @media (min-width: ${dimensions.laptop}) {
    max-width: 80%;
  }
`

export const ListWrapper = styled.ul`
  ${typography.bodyMd.regular};
  color: ${props => props.color};
  display: flex;
  flex-direction: column;
  list-style: circle;
  gap: 1rem;
`

export const ListItem = styled.li`
  line-height: 1.4rem;
`

export const SemiBold = styled.article`
  ${typography.bodyLg.semiBold};
`

export const ButtonContainer = styled.div<{
  padding?: string
  justifyStart?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.justifyStart ? 'flex-start' : 'center')};
  align-items: center;
  align-self: flex-start;
  gap: ${styling.container.gap.xl};
  padding: ${props => props.padding};
`

export const ImageWrapper = styled.figure<{
  squareImage?: boolean
  $background?: string
  padding?: boolean
  full?: boolean
  marginTop?: boolean
}>`
  display: flex;
  width: ${props =>
    props.full ? imageSizes.section.full : imageSizes.section.mobile};
  position: relative;
  background: ${props => props.$background};
  border-radius: ${styling.imageWrapper.borderRadius};
  padding: ${props => (props.padding ? '2rem' : '0')};
  align-self: center;
  margin-top: ${props => (props.marginTop ? '2rem' : '0')};
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  @media (min-width: ${dimensions.tablet}) {
    width: ${props =>
      ((props.squareImage && imageSizes.section.tablet) ||
        (props.full && imageSizes.section.full)) ??
      imageSizes.section.laptop};
    height: fit-content;
    padding: ${props => (props.padding ? '5rem' : '0')};
  }
  @media (min-width: ${dimensions.laptop}) {
    align-self: flex-start;
    width: ${props =>
      props.full ? imageSizes.section.full : imageSizes.section.laptop};
    padding: ${props => (props.padding ? '2rem' : '0')};
    margin: 0;
  }
`

export const SecondaryImageWrapper = styled.figure<{
  xSmall?: boolean
  marginTop?: boolean
  full?: boolean
}>`
  width: ${props =>
    ((props.xSmall && imageSizes.section.mobileSm) ||
      (props.full && imageSizes.section.full)) ??
    imageSizes.section.mobile};
  max-width: 100%;
  position: relative;
  border-radius: ${styling.imageWrapper.borderRadius};
  margin-top: ${props => (props.marginTop ? '1rem' : '0')};
  align-self: center;
  @media (min-width: ${dimensions.tablet}) {
    width: ${props =>
      props.full ? imageSizes.section.full : imageSizes.section.laptop};
    height: fit-content;
    margin: 0;
  }
  @media (min-width: ${dimensions.laptop}) {
    align-self: flex-start;
    width: ${props =>
      props.full ? imageSizes.section.full : imageSizes.section.laptop};
  }
`

export const BorderedImageWrapper = styled(SecondaryImageWrapper)<{
  borderColor?: string
}>`
  border: ${props => props.borderColor && `10px solid ${props.borderColor}`};
`

export const BaseImage = styled(Image).attrs(() => ({
  sizes: `(min-width: ${dimensions.laptop}) 40vw, 100vw`,
  placeholder: 'blur',
}))`
  width: 100%;
  height: auto;
`

export const SectionContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 1rem;
`

export const SectionText = styled.p`
  ${typography.bodyLg.regular}
  padding: 1rem 0rem;
`

export const SectionWrapper = styled.div<{
  gap?: boolean
}>`
  display: flex;
  flex-direction: column;

  @media (min-width: ${dimensions.tablet}) {
    width: 100%;
  }
  @media (min-width: ${dimensions.laptop}) {
    flex-direction: row;
    gap: ${props => (props.gap ? styling.container.gap.lg : '0')};
  }
`
