import React, { CSSProperties, forwardRef, ReactNode } from 'react'

import * as S from './styled'

type Props = {
  children: ReactNode
  backgroundColor?: string
  className?: string
  style?: CSSProperties
  id?: string
}

const Container = forwardRef<HTMLDivElement, Props>(
  ({ children, backgroundColor, className, style, id }, ref) => {
    return (
      <S.ComponentContainer ref={ref} backgroundColor={backgroundColor} id={id}>
        <S.ComponentWrapper className={className} style={style}>
          {children}
        </S.ComponentWrapper>
      </S.ComponentContainer>
    )
  }
)

Container.displayName = 'Container'

export default Container
