import styled from 'styled-components'

import { dimensions, styling } from '#styles'

export const ComponentContainer = styled.div<{
  backgroundColor?: string
}>`
  display: flex;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
`

export const ComponentWrapper = styled.div`
  width: 100%;
  max-width: ${styling.container.maxWidth.normal};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: ${styling.wrapper.mobilePadding};
  @media (min-width: ${dimensions.tablet}) {
    gap: 2rem;
    padding: ${styling.wrapper.tabletPadding.sm};
  }
  @media (min-width: ${dimensions.laptop}) {
    gap: 2.5rem;
    padding: ${styling.wrapper.laptopPadding.sm};
  }
`
