import Image from 'next/image'
import styled from 'styled-components'

import { dimensions, imageSizes } from '#styles'

export const PageWrapper = styled.main`
  width: 100%;
  scroll-behavior: smooth;
`

export const ShadowedImage = styled(Image).attrs(() => ({
  placeholder: 'blur',
}))<{
  shadowColor: string
  shadowWidth: string
  squareImage?: boolean
  sizes: string
}>`
  object-fit: cover;
  object-position: right;
  max-width: 20rem;
  aspect-ratio: 1;
  height: auto;
  box-shadow: ${props => props.shadowWidth} ${props => props.shadowWidth} 0
    ${props => props.shadowColor};
  @media (min-width: ${dimensions.tablet}) {
    height: ${props => props.squareImage && imageSizes.shadowed.tablet};
    width: ${props => props.squareImage && imageSizes.shadowed.tablet};
    max-width: 100%;
  }
  @media (min-width: ${dimensions.tablet}) {
    aspect-ratio: unset;
  }
  @media (min-width: ${dimensions.laptop}) {
    aspect-ratio: 1;
    width: ${imageSizes.shadowed.laptop};
  }
`

export const StyledImage = styled(Image)`
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
`
