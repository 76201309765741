import Head from 'next/head'
import React, { FC, ReactNode } from 'react'

import { LangHref } from '../../lib/createLangHrefs'

type Props = {
  langHrefs: LangHref[]
  title: string
  description: string
  children?: ReactNode
}

const HeadComp: FC<Props> = ({ langHrefs, title, description, children }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {langHrefs.map(({ href, hrefLang, rel }) => (
        <link rel={rel} hrefLang={hrefLang} href={href} key={href} />
      ))}
      {children}
    </Head>
  )
}

export default HeadComp
